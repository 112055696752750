/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SearchComponent } from './search/search.component';
import { SimpleInputComponent } from './simple-input/simple-input.component';
import { MenuComponent } from './menu/menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { OptionComponent } from '@views/reusable/options/option/option.component';
import { OptionSelectableComponent } from '@views/reusable/options/option-selectable/option-selectable.component';
import { OptionQuantityControlComponent } from './options/option-quantity-control/option-quantity-control.component';
import { ResumeItemComponent } from './resume-item/resume-item.component';
import { QuantityInputComponent } from './quantity-input/quantity-input.component';
import { LoaderComponent } from './loader/loader.component';
import { SelectActivityTypeModalComponent } from '@app/views/reusable/modals/select-activity-type-modal/select-activity-type-modal.component';
import { EmailLinkModalComponent } from '@views/reusable/modals/email-link/email-link-modal.component';
import { SendTicketComponent } from '@views/reusable/modals/send-ticket/send-ticket.component';
import { SendTicketConstructorComponent } from '@views/reusable/modals/send-ticket-constructor/send-ticket-constructor.component';
import { ExplanationModalComponent } from './modals/explanation-modal/explanation-modal.component';
import { OptionTicketsComponent } from './options/option-tickets/option-tickets.component';
import { IssuedTicketModalComponent } from './modals/issued-ticket-modal/issued-ticket-modal.component';
import { SelectLotWarningComponent } from '@views/reusable/modals/unused/select-lot-warning/select-lot-warning.component';
import { TicketOtherModalComponent } from '@views/reusable/modals/unused/ticket-other-modal/ticket-other-modal.component';
import { UserNotFoundModalComponent } from './modals/user-not-found/user-not-found-modal.component';
import { RouterModule } from '@angular/router';
import { ConstructionComponent } from './construction/construction.component';
import { SelectRoleComponent } from './modals/select-role/select-role.component';
import { LogoutModalComponent } from './modals/logout-modal/logout-modal.component';
import { SelectReceiverModalComponent } from './modals/select-reciever-modal/select-receiver-modal.component';
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { IssuedTicketErrorsModalComponent } from './modals/issued-ticket-errors-modal/issued-ticket-errors-modal.component';
import { MatSelectSearchComponent } from './mat-select-search/mat-select-search.component';
import { WhatsappShareComponent } from './whatsapp-share/whatsapp-share.component';
import { MaterialsFormSelectComponent } from './materials-form-select/materials-form-select.component';
import { MaterialsFormComponent } from './materials-form/materials-form.component';
import { ErrorModalComponent } from './modals/error-modal/error-modal.component';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { WarehouseNoValueComponent } from './warehouse-no-value/warehouse-no-value.component';
import { OneColumnCardComponent } from './one-column-card/one-column-card.component';
import { TwoColumnCardComponent } from './two-column-card/two-column-card.component';
import { OneColumnDropdownCardComponent } from './one-column-dropdown-card/one-column-dropdown-card.component';
import { TwoColumnStaticCardComponent } from './two-column-static-card/two-column-static-card.component';
import { OneColumnButtonCardComponent } from './one-column-button-card/one-column-button-card.component';
import { OneColumnUploadCardComponent } from './one-column-upload-card/one-column-upload-card.component';
import { OneColumnStaticCardComponent } from './one-column-static-card/one-column-static-card.component';
import { OneColumnSmartCardComponent } from './one-column-smart-card/one-column-smart-card.component';
import { TwoColumnNumberCardComponent } from './two-column-number-card/two-column-number-card.component';
import { NumberInputComponent } from './number-input/number-input.component';
import { UploadComponent } from './upload/upload.component';
import { SimpleMessageComponent } from './modals/simple-message/simple-message.component';
import { SimpleConfirmComponent } from './modals/simple-confirm/simple-confirm.component';
import {
  NiceDecimalsPipe,
  NiceDatePipe,
  NiceCurrencyPipe,
  NiceRutPipe,
  CapitalizeEachPipe,
  NiceFloorPipe,
} from '@app/pipes';
import { SimpleQuestionComponent } from './modals/simple-question/simple-question.component';
import { ExtraInfoModalComponent } from '@views/reusable/modals/extra-info-modal/extra-info-modal.component';
import { OneColumnDropdownMultiselectCardComponent } from './one-column-dropdown-multiselect-card/one-column-dropdown-multiselect-card.component';
import { MultiselectComponent } from './modals/multiselect/multiselect.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TwoColumnButtonCardComponent } from './two-column-button-card/two-column-button-card.component';
import { ActionOverReceptionComponent } from './modals/action-over-reception/action-over-reception.component';
import { NoContentBox } from './no-content-box/no-content-box-component';
import { OptionReceptionsComponent } from './options/option-receptions/option-receptions.component';
import { ReceptionsummaryComponent } from './reception-summary/reception-summary.component';
import { FileListComponent } from './file-list/file-list.component';
import { SelectDateComponent } from './modals/select-date/select-date.component';
import { FormCardComponent } from './form-card/form-card.component';
import { ButtonComponent } from './button/button.component';
import { SelectComponent } from './select/select.component';
import { TableComponent } from './table/table.component';
import { TabComponent } from './tab/tab.component';
import { TableVirtualScrollModule } from 'ng-table-virtual-scroll';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfoWrapperComponent } from './info-wrapper/info-wrapper.component';
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';
import { FormCardsWrapperComponent } from './form-cards-wrapper/form-cards-wrapper.component';
import { InfoFormCardComponent } from './info-form-card/info-form-card.component';
import { FormFooterComponent } from './form-footer/form-footer.component';
import { FormHeaderComponent } from './form-header/form-header.component';
import { FormTableComponent } from './form-table/form-table-card.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IsEllipsisActiveDirective } from '../../directives/isEllipsisActive';
import { OneColumnDateCardComponent } from './one-column-date-card/one-column-date-card.component';
import { SelectSpecialtyContractComponent } from './modals/select-specialty-contract/select-specialty-contract.component';
import { UploadModalComponent } from './modals/upload-modal/upload-modal.component';
import { DoubleSelectComponent } from './modals/double-select/double-select.component';
import { NoContentBoxSmall } from './no-content-small/no-content-small-component';
import { SelectBranchComponent } from './modals/select-branch/select-branch.component';
import { ConfirmWithCheckboxComponent } from './modals/confirm-with-checkbox/confirm-with-checkbox.component';

@NgModule({
  declarations: [
    ButtonComponent,
    SelectComponent,
    SelectMultipleComponent,
    NiceDecimalsPipe,
    NiceDatePipe,
    NiceCurrencyPipe,
    NiceRutPipe,
    NiceFloorPipe,
    CapitalizeEachPipe,
    SearchComponent,
    SimpleInputComponent,
    MenuComponent,
    OptionComponent,
    OptionSelectableComponent,
    ExtraInfoModalComponent,
    ResumeItemComponent,
    QuantityInputComponent,
    OptionQuantityControlComponent,
    SelectActivityTypeModalComponent,
    SelectReceiverModalComponent,
    EmailLinkModalComponent,
    LoaderComponent,
    SendTicketComponent,
    SendTicketConstructorComponent,
    ExplanationModalComponent,
    SelectLotWarningComponent,
    OptionTicketsComponent,
    IssuedTicketModalComponent,
    TicketOtherModalComponent,
    UserNotFoundModalComponent,
    ConstructionComponent,
    SelectRoleComponent,
    LogoutModalComponent,
    TicketListComponent,
    TicketDetailsComponent,
    IssuedTicketErrorsModalComponent,
    MatSelectSearchComponent,
    WhatsappShareComponent,
    MaterialsFormSelectComponent,
    MaterialsFormComponent,
    ErrorModalComponent,
    DateFilterComponent,
    WarehouseNoValueComponent,
    OneColumnCardComponent,
    TwoColumnCardComponent,
    OneColumnDropdownCardComponent,
    TwoColumnStaticCardComponent,
    OneColumnButtonCardComponent,
    OneColumnUploadCardComponent,
    OneColumnStaticCardComponent,
    OneColumnSmartCardComponent,
    TwoColumnNumberCardComponent,
    NumberInputComponent,
    UploadComponent,
    SimpleMessageComponent,
    SimpleConfirmComponent,
    SimpleQuestionComponent,
    OneColumnDropdownMultiselectCardComponent,
    MultiselectComponent,
    TwoColumnButtonCardComponent,
    ActionOverReceptionComponent,
    NoContentBox,
    NoContentBoxSmall,
    OptionReceptionsComponent,
    ReceptionsummaryComponent,
    FileListComponent,
    SelectDateComponent,
    FormCardComponent,
    TableComponent,
    TabComponent,
    InfoWrapperComponent,
    FormCardsWrapperComponent,
    InfoFormCardComponent,
    FormHeaderComponent,
    FormFooterComponent,
    FormTableComponent,
    IsEllipsisActiveDirective,
    OneColumnDateCardComponent,
    SelectSpecialtyContractComponent,
    UploadModalComponent,
    DoubleSelectComponent,
    SelectBranchComponent,
    ConfirmWithCheckboxComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatInputModule,
    MatProgressBarModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    RouterModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatOptionModule,
    MatTabsModule,
    MatDatepickerModule,
    MatCardModule,
    MatListModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    TableVirtualScrollModule,
    ScrollingModule,
    MatTooltipModule,
  ],
  exports: [
    ButtonComponent,
    SelectComponent,
    SelectMultipleComponent,
    NiceDecimalsPipe,
    NiceDatePipe,
    NiceCurrencyPipe,
    NiceRutPipe,
    NiceFloorPipe,
    CapitalizeEachPipe,
    SearchComponent,
    SimpleInputComponent,
    MenuComponent,
    ResumeItemComponent,
    OptionComponent,
    MatProgressBarModule,
    OptionSelectableComponent,
    ExtraInfoModalComponent,
    OptionQuantityControlComponent,
    LoaderComponent,
    ConstructionComponent,
    OptionTicketsComponent,
    QuantityInputComponent,
    TicketListComponent,
    TicketDetailsComponent,
    MatSelectSearchComponent,
    WhatsappShareComponent,
    MaterialsFormSelectComponent,
    MaterialsFormComponent,
    DateFilterComponent,
    WarehouseNoValueComponent,
    OneColumnCardComponent,
    TwoColumnCardComponent,
    OneColumnDropdownCardComponent,
    TwoColumnStaticCardComponent,
    OneColumnButtonCardComponent,
    OneColumnUploadCardComponent,
    OneColumnStaticCardComponent,
    OneColumnSmartCardComponent,
    UploadComponent,
    NumberInputComponent,
    OneColumnDropdownMultiselectCardComponent,
    NgxMatSelectSearchModule,
    TwoColumnButtonCardComponent,
    NoContentBox,
    NoContentBoxSmall,
    OptionReceptionsComponent,
    ReceptionsummaryComponent,
    FileListComponent,
    SelectDateComponent,
    FormCardComponent,
    TableComponent,
    TabComponent,
    TableVirtualScrollModule,
    InfoWrapperComponent,
    FormCardsWrapperComponent,
    FormHeaderComponent,
    FormFooterComponent,
    FormTableComponent,
  ],
  providers: [
    NiceDecimalsPipe,
    NiceDatePipe,
    NiceCurrencyPipe,
    NiceRutPipe,
    NiceFloorPipe,
    CapitalizeEachPipe,
    DecimalPipe,
  ],
})
export class ReusableModule {}
